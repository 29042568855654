import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Alarm/Object_Detection/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/AlarmLivestreamPerm';
import SettingsTable from 'components/Web_User_Interface/1440p_Series/Alarm/Object_Detection/settingsTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm Menu // Object Detection",
  "path": "/Web_User_Interface/1440p_Series/Alarm/Object_Detection/",
  "dateChanged": "2022-11-08",
  "author": "Mike Polinowski",
  "excerpt": "Use the Object Detection menu to place two bounding boxes your video image. The outer box represents the maximum size a detected object is allowed to have to trigger an alert. And the inner box defines the minimum size that is required for a detection to be treated as an alarm.",
  "image": "./WebUI_1440p_SearchThumb_Alarm_Alarm_Actions.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Alarm_Alarm_Actions.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Alarm Menu // Object Detection' dateChanged='2022-11-08' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the Object Detection menu to place two bounding boxes your video image. The outer box represents the maximum size a detected object is allowed to have to trigger an alert. And the inner box defines the minimum size that is required for a detection to be treated as an alarm.' image='/images/Search/WebUI_1440p_SearchThumb_Alarm_Alarm_Actions.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Alarm_Alarm_Actions.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Alarm/Objekt_Erkennung/' locationFR='/fr/Web_User_Interface/1440p_Series/Alarm/Object_Detection/' crumbLabel="Object Erkennung" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "alarm-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-menu",
        "aria-label": "alarm menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Menu`}</h2>
    <h3 {...{
      "id": "object-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#object-detection",
        "aria-label": "object detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Object Detection`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "adjust-your-object-detection-parameter",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adjust-your-object-detection-parameter",
        "aria-label": "adjust your object detection parameter permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adjust your Object Detection Parameter`}</h2>
    <p>{`Note that the `}<strong parentName="p">{`position of the bounding box is not important`}</strong>{`. Placing it on top of a camera snapshot helps to determine the size of both boxes. Only the width and height parameter is going to be used to filter detection that do not fall in between those minimum and maximum values.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/db2959e8b236d167798374feb02984a1/610c0/1440p_Settings_Object_Detection_Bounding_Boxes.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7rAAAO6wFxzYGVAAADg0lEQVQ4y3XQzW8bRRzG8f1P4rR10uIUaCOqtoJgO86bX3Z31ruzL97YsdeOndhxnDecNiqx0yZEgXIAKlBBpSriRVV5EZWQSFqEQAj1AleO3Lkirl/kNFTtgcNHz6OZ0Wjmp4hrD1G39klvPSDZfcBU5+DQZOeAic0jvd7ZP1xLdvZJdh9nqntAqnNAprOPvfczY7N7KI3mOo35VXJeGd8NyLsBBbdExS8x5wfMT1eY8yvU81UquVlcr4qUZTyvStYqIfM17EINP2gwOqmiTL0kSZ0zmToryAxrGOcyeBfT1BIZNk2V932Nu4HOTVenMyloxTVWprK0VYemaePYFp4jybmS6KuvoMRfNBh5QcWPV6l7bXy3hWctIpJVEhck8TMGC6rF/SsNfuk2WE/5TGdKLE3ZNO08fsFHU1UMyyYaH0cRI1m0C4KKN0ezfpmy16Totcj7CyTHcpx/3iB+VrBTXeCP2xt8sLaKniiyPCFpSB/btTEdH9vJEYvFUeJnBMUxm3ZliflgGXfcIJ8tszDbYqlYoJo0CMYd9uan+XIlz1Utixe12EhJqmmJaVkYWRPLsohFoyiFCY/KpMPSdJ3Xqi20aIZpPaDTXOa9tRnurNh894bPbzdsHt3Ic3Mtx601jy/WJZuBiXRcsqbEkpJYLIZyzfeRLxsUxSyXa4ucjyTQx/K0gyZt02JnxuHbbZu/Hi7z9/cL/P5ukc+2K/zwToE716tkTYusdJG2TbT3wrV0liCmE4gy5dwC8eE0MjlDza3RnXH5eNXh647Dnx/m+eeWy4+XPD7ZKfPVToGP9uZxczkMI3v45dHRBEoxapIe1vFSAfXSKrVci0a+xe7iIvc2Pe5u2Hy+ZvHTlsOjqw7fXHG4//YMv35a5/b1KsIwke40ju2QSCRQ5KRF4qKGTHkEuTJFu8isV2IlKLFRMHi9ZNCtGLzVklxfttluSLpzJm+uSFaKGplUkrTQyZhZor0Z7jZUFmc0VCEwDB0hBMIQ6EJHFwbaU1S9R6DpgoxqoBcWMSttGrrJJU0w0Zvhbl2l4adJZXR0XUPT/p9+5LDrGqLQRC+uMpcWtNMq4yMjKMcGhxg8NcTp00NEIhHC4fAzBgbCDPyXT/fe/vEQ4WN99A+E6Rsc4PiJEyi9S56LDHHyVISBwZP09/c/EQqF6Ovre5LPChEK9T/WF6L/6Ny/egtouAqU/icAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/db2959e8b236d167798374feb02984a1/e4706/1440p_Settings_Object_Detection_Bounding_Boxes.avif 230w", "/en/static/db2959e8b236d167798374feb02984a1/d1af7/1440p_Settings_Object_Detection_Bounding_Boxes.avif 460w", "/en/static/db2959e8b236d167798374feb02984a1/7f308/1440p_Settings_Object_Detection_Bounding_Boxes.avif 920w", "/en/static/db2959e8b236d167798374feb02984a1/42d56/1440p_Settings_Object_Detection_Bounding_Boxes.avif 1295w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/db2959e8b236d167798374feb02984a1/a0b58/1440p_Settings_Object_Detection_Bounding_Boxes.webp 230w", "/en/static/db2959e8b236d167798374feb02984a1/bc10c/1440p_Settings_Object_Detection_Bounding_Boxes.webp 460w", "/en/static/db2959e8b236d167798374feb02984a1/966d8/1440p_Settings_Object_Detection_Bounding_Boxes.webp 920w", "/en/static/db2959e8b236d167798374feb02984a1/a8acc/1440p_Settings_Object_Detection_Bounding_Boxes.webp 1295w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/db2959e8b236d167798374feb02984a1/81c8e/1440p_Settings_Object_Detection_Bounding_Boxes.png 230w", "/en/static/db2959e8b236d167798374feb02984a1/08a84/1440p_Settings_Object_Detection_Bounding_Boxes.png 460w", "/en/static/db2959e8b236d167798374feb02984a1/c0255/1440p_Settings_Object_Detection_Bounding_Boxes.png 920w", "/en/static/db2959e8b236d167798374feb02984a1/610c0/1440p_Settings_Object_Detection_Bounding_Boxes.png 1295w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/db2959e8b236d167798374feb02984a1/c0255/1440p_Settings_Object_Detection_Bounding_Boxes.png",
              "alt": "Web User Interface - 1440p Series - Object Detection Parameter",
              "title": "Web User Interface - 1440p Series - Object Detection Parameter",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <SettingsTable mdxType="SettingsTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      